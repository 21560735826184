import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const DashboardPagos = () => {
  const [loading, setLoading] = useState(true);  // Estado de carga
  const [error, setError] = useState(null);  // Estado para errores (si es necesario)
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem('token');
  
    if (!token) {
      setError('No tienes acceso a esta página. Por favor, inicia sesión.');
      navigate('/login');
    } else {
      setLoading(false); // Configura loading como false directamente si hay token
    }
  }, [navigate]);

  if (loading) {
    return <div>Loading...</div>;  // Mostrar un mensaje de carga mientras validas el token
  }

  if (error) {
    return (
      <div role="alert" aria-live="assertive">
        <p>{error}</p>
      </div>
    );  // Mostrar mensaje de error si hay alguno
  }

  return (
    <div>
      <h1>Bienvenido al Dashboard de Pagos</h1>
      <p>Aquí podrás gestionar las transacciones y pagos del sistema.</p>
      {/* Aquí puedes mostrar la información del usuario y las funcionalidades de pagos */}
    </div>
  );
};

export default DashboardPagos;
