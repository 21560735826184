import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Login from './pages/Login';
import Register from './pages/Register';
import ForgotPassword from './pages/ForgotPassword';
import ResetPassword from './pages/ResetPassword';
import DashboardAdmin from './pages/DashboardAdmin';
import DashboardUser from './pages/DashboardUser';
import DashboardComercial from './pages/DashboardComercial';
import DashboardGestion from './pages/DashboardGestion';
import DashboardPagos from './pages/DashboardPagos';

/**
 * Componente principal que define las rutas de la aplicación
 * Incluye rutas públicas y rutas de los dashboards correspondientes
 */
const App = () => {
  return (
    <Router>
      <Routes>
        {/* Rutas públicas */}
        <Route path="/" element={<Login />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password/:token" element={<ResetPassword />} />

        {/* Rutas de los dashboards */}
        <Route path="/dashboard/admin" element={<DashboardAdmin />} />
        <Route path="/dashboard/comercial" element={<DashboardComercial />} />
        <Route path="/dashboard/gestion" element={<DashboardGestion />} />
        <Route path="/dashboard/pagos" element={<DashboardPagos />} />
        <Route path="/dashboard/user" element={<DashboardUser />} />
      </Routes>
    </Router>
  );
};

export default App;
