import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const DashboardComercial = () => {
  const [loading, setLoading] = useState(true);  // Estado de carga para la validación del token
  const [error, setError] = useState(null); // Estado para mostrar errores si es necesario
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem('token');
  
    if (!token) {
      setError('No tienes acceso a esta página. Por favor, inicia sesión.');
      navigate('/login');
    } else {
      setLoading(false); // Configura loading como false directamente si hay token
    }
  }, [navigate]);


  if (loading) {
    return <div>Loading...</div>;  // Mostrar un mensaje de carga o spinner mientras validas el token
  }

  if (error) {
    return <div role="alert">{error}</div>;  // Mostrar el error si existe
  }

  return (
    <div>
      <h1>Bienvenido al Dashboard Comercial</h1>
      {/* Aquí puedes mostrar la información específica para el usuario comercial */}
    </div>
  );
};

export default DashboardComercial;
